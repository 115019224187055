.tijd-thema {
	background-color: #34495E;

	.navbar {
		height: 3em;
		min-height: 3em;

		@include from($tablet) {
			margin-top: 10px;
			background-color: transparent;
			height: 3.5em;
			min-height: 3.5em;
		}

		.navbar-menu {
			padding: 0;

			@include from($tablet) {
				height: 3.5em;
			}
		}

		.navbar-brand,
		.container {
			height: 3em;
			min-height: 3em;

			@include from($tablet) {
				height: 3.5em;
				min-height: 3.5em;
			}
		}

		.navbar-start {
			border-top: 1px solid #fff;

			@include from($tablet) {
				border: 0;
			}
		}

		.navbar-item {
			padding: 1rem 0.75rem;
			border-bottom: 1px solid #fff;

			@include from($tablet) {
				margin-right: 10px;
				padding: 0.5rem 0.75rem;
				border: 0;
			}

			&:last-child {
				@include from($tablet) {
					margin-right: 0;
				}
			}

			&--lente {
				background-color: #ffdb73;
				color: #f9a50a;
			}

			&--koningsdag {
				background: orange;
				color: #fff;
			}

			&--eigen-timer {
				background: #3cb371;
				color: #fff;
			}

			&--historie {
				background: #ef793e;
				color: #fff;
			}

			&--kerst {
				background: maroon;
				color: #fff;
			}

			&--zomer {
				background: #7ec0ee;
				color: #fff;
			}
		}

		.navbar-burger {
			height: 3em;
			min-height: 3em;

			@include from($tablet) {
				height: 3.5em;
				min-height: 3.5em;
			}
		}
	}

	.section {
		&.lente {
			background-color: #ffdb73;

			.countdown {
				.card-content {
					h2 {
						color: #f9a50a;
					}
				}
			}
		}

		&.koningsdag {
			background: orange;

			.countdown {
				.card-content {
					.countdown-event--koningsdag {
						.circle {
							border: solid 2px #fff;
							background: #f8ac21;
							background: linear-gradient(#f8ac21, #faba44);

							@include from($tablet) {
								border: solid 3px #fff;
							}
						}

						.text {
							color: #fff;
						}
					}
				}
			}
		}

		&.eigen-timer {
			background: #3cb371;

			.countdown {
				.card-content {
					.countdown-event--eigen-timer {
						.circle {
							border: solid 2px #1f9e58;
							background: #3cb371;
							background: linear-gradient(#24a75e, #72bd94);

							@include from($tablet) {
								border: solid 3px #1f9e58;
							}
						}

						.text {
							color: #fff;
						}
					}
				}
			}
		}

		&.external-page {
			.countdown {
				.card-content {
					margin: 0;
					padding: 0;

					h2 {
						margin: 0;
						padding: 0.3em;
						font-size: 2.5em;

						@include from($tablet) {
							font-size: 4.5em;
						}
					}
				}
			}

			&--kerst {
				background-color: maroon;

				.countdown {
					.card-content {
						h2 {
							border: solid 1px #000;
							background: #5b0000;
							background: linear-gradient(#5b0000, #751818);
						}
					}
				}
			}

			&--zomer {
				background-color: #7ec0ee;

				.countdown {
					.card-content {
						h2 {
							border: solid 1px #fff;
							background: #61b6f2;
							background: linear-gradient(#61b6f2, #51b0f4);
						}
					}
				}
			}
		}
	}

	.countdown {
		color: #fff;
	}

	.card-content {
		margin-top: 1em;
		padding: .5rem;

		@include from($tablet) {
			margin-top: 7em;
			padding: 1.5rem;
		}

		h1 {
			font-family: 'Caveat Brush', cursive;
			color: #fff;
			font-size: 4em;
			text-shadow: 2px 2px #000;

			@include from($tablet) {
				font-size: 7em;
			}
		}

		h2 {
			font-family: 'Caveat Brush', cursive;
			color: #fff;
			font-size: 3em;
			text-shadow: 2px 2px #000;

			@include from($tablet) {
				font-size: 5.5em;
			}
		}

		h4 {
			font-family: 'Amatic SC', cursive;
			color: #fff;
			font-size: 1.6em;
			line-height: 1.3;
			text-shadow: 1px 1px #000;
			text-align: center;

			@include from($tablet) {
				font-size: 2.2em;
			}
		}

		.countdown-event {
			margin-top: 2em;

			@include from($tablet) {
				margin-top: 4em;
			}

			.circles {
				display: inline-block;
				margin-right: .9em;

				@include from($tablet) {
					margin-right: 1.6em;
				}

				&:last-child {
					margin-right: 0;
				}

				.circle {
					display: block;
					border: solid 2px #f9a50a;
					border-radius: 50%;
					width: 2.4em;
					height: 2.4em;
					padding: 0.35em 0.27em;
					text-align: center;
					font-size: 1.4em;
					font-weight: bold;
					background: #eaa932;
					background: linear-gradient(#eaa932, #f2c97f);

					@include from($tablet) {
						border: solid 3px #f9a50a;
						width: 2.5em;
						height: 2.5em;
						padding: 0.45em 0.3em;
						font-size: 3em;
					}
				}

				.text {
					display: block;
					color: #f9a50a;
					text-transform: uppercase;
					font-weight: bold;
					font-size: 0.5em;
					margin-top: 1em;

					@include from($tablet) {
						font-size: 0.8em;
					}
				}
			}
		}
	}

	main {
		min-height: calc(100vh - 270px);
	}

	footer {
		padding: 0;
		margin: 0;
		background: none;
		text-align: center;
		right: 0;
		bottom: 0;
		left: 0;
		width: 100%;

		.img-container {
			overflow: hidden;

			img {
				margin: 0 0 0 -960px;
				padding: 0;
				display: block;
				height: 270px;
				width: auto;
				max-width: initial;

				@include from($tablet) {
					margin: 0;
					height: auto;
					width: auto;
					max-width: 100%;
				}
			}
		}

		.content {
			background-color: #34495E;
			color: #fff;
			font-size: 0.65em;
			padding: 1.2em 0;

			a {
				color: #7ec0ee;

				&:hover {
					color: #61b6f2;
				}
			}
		}
	}
}
