html,
body {
	position: relative;
	margin: 0;
	padding: 0;
	font-family: 'Open Sans', sans-serif;
	font-size: 16px;
	background: #F0F2F4;
	scroll-behavior: smooth;
}

.navbar {
	.is-white {
		background: #F0F2F4;
	}
}

.hero-body {
	background-image: url(https://upload.wikimedia.org/wikipedia/commons/thumb/f/f6/Plum_trees_Kitano_Tenmangu.jpg/1200px-Plum_trees_Kitano_Tenmangu.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	height: 500px;
}

.articles {
	margin: 5rem 0;
	margin-top: -200px;

	.content p {
		line-height: 1.9;
		margin: 15px 0;
	}

	.article-title {
		font-size: 2rem;
		font-weight: lighter;
		line-height: 2;
	}

	.article {
		margin-top: 6rem;

		.article-body {
			line-height: 1.4;
			margin: 0 6rem;
		}
	}
}

.media-content {
	margin-top: 3rem;
}

.promo-block {
	margin-top: 6rem;

	.container {
		margin: 1rem 5rem;
	}
}

.column {
	&.is-8 {
		&:first-child {
			padding-top: 0;
			margin-top: 0;
		}
	}
}

.cookie-wall {
	display: none;
	position: fixed;
	bottom: 0;
	width: 100%;
	text-align: center;
	font-size: 0.7em;
	background-color: #ccdbee;
    margin-left: 0;
	padding: 1.2em 0;

	p {
		display: inline-block;
	}

	.consent-button {
		display: inline-block;
	}
}
